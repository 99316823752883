<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <!-- <pre>{{ state }}</pre> -->
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <div class="card-placeholder ml-5">
          <p class="user-select-none">Unggah Dokumen</p>
        </div>
      </div>
      <div class="col-lg-9 col-md-12">
        <div class="card-placeholder">
          <a-input
            v-if="show"
            required
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            @change="e => onFileChange(e, 'ktp')"
          ></a-input>
          <a-button @click="unduhTemplate">Unduh Template</a-button>
          <a-button v-if="file" @click="unggah" type="primary">Unggah</a-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body">
            <div class="table-responsive">
              <md-table
                row-key="no"
                :row-selection="rowSelection"
                :columns="columns"
                :data-source="data"
                class="ant-table-striped"
                :row-class-name="coloringRow"
                ><template #tags="{ text, record }">
                  <a-tag :color="text ? 'green' : 'volcano'">
                    <template #icon>
                      <template v-if="text"> <check-circle-outlined /> Ya </template>
                      <template v-else>
                        <a-popover trigger="click">
                          <template #content>
                            <a-list size="small" :data-source="record.errors"
                              ><template #renderItem="{ item }">
                                <a-list-item>{{ item }}</a-list-item>
                              </template>
                            </a-list>
                          </template>
                          <close-circle-outlined /> Tidak
                        </a-popover>
                      </template>
                    </template>
                  </a-tag>
                </template>
                <template #required="{columns}">
                  <template v-if="columns.title === 'SSM'">
                    <span>
                      <smile-outlined />
                    </span>
                  </template>
                </template>
              </md-table>
            </div>
          </div>
        </div>
        <div class="card-placeholder">
          <div class="card-body ml-3">
            <a-button
              :disabled="selectedRows.length === 0"
              type="primary"
              @click="simpan"
              :loading="saving"
              >Simpan</a-button
            >
            <!-- <a-button type="primary" @click="test">Test</a-button> -->
          </div>
        </div>
      </div>
    </div>
    <a-modal v-model:visible="showResult" title="Result" @ok="showResult = false">
      <template #footer>
        <a-button key="back" type="primary" @click="handleCancel">OK</a-button>
      </template>
      <a-alert type="success" :message="`Sukses: ${result.sukses}`"></a-alert>
      <a-alert type="error">
        <template #message>
          {{ `Gagal: ${result.gagal}`
          }}<a-tree :tree-data="treeData" default-expand-all></a-tree> </template
      ></a-alert>
    </a-modal>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import { message } from 'ant-design-vue'
import { ref, nextTick, reactive, toRefs, computed } from 'vue'
import columns from './columns'
import { CheckCircleOutlined, CloseCircleOutlined, SmileOutlined } from '@ant-design/icons-vue'
import moment from 'moment'

export default {
  name: 'VbDashboard',
  setup() {
    const file = ref()
    const data = ref([])
    const show = ref(true)
    const showResult = ref(false)

    const state = reactive({
      selectedRowKeys: [],
      selectedRows: [],
      // Check here to configure the default column
      loading: false,
      saving: false,
      result: { sukses: 0, gagal: 0 },
      treeData: [],
    })

    const onFileChange = (e, type) => {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      file.value = files[0]
    }
    const errorMessage = ref()
    const unggah = () => {
      const form_data = new FormData()

      form_data.append('file', file.value)

      apiClient
        .post('/api/loyalty-program-stores/upload', form_data)
        .then(({ data: _data }) => {
          _data.forEach((e, i) => (e.no = i))
          data.value = _data
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response && error.response.status !== 500) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
          if (error.response && error.response.status === 500) {
            const { status, statusText } = error.response
            const message = error.response.data.message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
          message.error('Gagal mengupload!')
        })
        .finally(() => {
          file.value = null
          show.value = false
          nextTick().then(() => {
            show.value = true
          })
        })
    }
    const rowSelection = computed(() => {
      return {
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          state.selectedRowKeys = selectedRowKeys
          state.selectedRows = selectedRows
        },
        getCheckboxProps: record => ({
          disabled: !record.valid,
          // Column configuration not to be checked
          // name: record.name,
        }),
      }
    })

    const simpan = () => {
      state.treeData = []

      errorMessage.value = null
      state.saving = true
      apiClient
        .post('/api/loyalty-program-stores/upload', { simpan: state.selectedRows })
        .then(response => {
          const dataResp = response.data

          if (Array.isArray(dataResp) && dataResp.length === 0) {
            message.warning('Tidak ada data!, pastikan sudah memilih datanya')
            return
          }
          showResult.value = true

          state.result = dataResp.reduce(
            (p, c) => {
              p.sukses += c.success ? 1 : 0
              p.gagal += !c.success ? 1 : 0
              return p
            },
            { sukses: 0, gagal: 0 },
          )
          dataResp.forEach((value, idx) => {
            if (value.success === false || value.errors) {
              const branch = {}
              state.treeData.push(branch)
              branch.title = 'Baris ' + (idx + 1)
              branch.key = idx
              branch.children = []
              if (value.errors) {
                value.errors.forEach((v, i) => {
                  branch.children.push({
                    title: `Kolom ${v.field} : ${v.message}`,
                    key: `${idx}-${i}`,
                  })
                })
              }
            }
          })

          data.value = []
        })
        .catch(async error => {
          // message.error('Gagal menyimpan!')
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = error.response.data.message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.saving = false
          state.selectedRowKeys = []
          state.selectedRows = []
        })
    }

    const test = () => {
      state.selectedRowKeys = data.value.filter(row => row.valid).map(row => row.no)
    }

    const unduhTemplate = () => {
      apiClient
        .get('/sample-upload/loyalty-program-toko-whitelist.xlsx', {
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `loyalty-program-toko-whitelist_${moment().format('DDMMYY_HHmm')}.xlsx`,
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
    }

    const coloringRow = (record, index) => {
      return record.action === 'delete' ? 'table-striped' : null
    }

    const handleCancel = () => {
      showResult.value = false
    }

    return {
      data,
      columns,
      onFileChange,
      unggah,
      rowSelection,
      CheckCircleOutlined,
      CloseCircleOutlined,
      SmileOutlined,
      show,
      file,
      ...toRefs(state),
      state,
      simpan,
      test,
      unduhTemplate,
      errorMessage,
      showResult,
      coloringRow,
      handleCancel,
    }
  },
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td,
.ant-table-striped :deep(.table-striped:hover) td {
  background-color: #fa6d6d !important;
  color: rgb(255, 255, 255);
}
</style>
