export default [
  {
    title: 'Valid?',
    dataIndex: 'valid',
    slots: {
      customRender: 'tags',
    },
  },
  {
    title: 'Kode Program',
    dataIndex: 'program',
  },
  {
    title: 'Nama Program',
    dataIndex: 'program_name',
  },
  {
    title: 'Region Program',
    dataIndex: 'program_region',
  },
  {
    title: 'Kode Toko',
    dataIndex: 'customer_code',
  },
  {
    title: 'Nama Toko',
    dataIndex: 'customer_name',
  },
  {
    title: 'Area Toko',
    dataIndex: 'customer_area',
  },
  {
    title: 'Region Toko',
    dataIndex: 'customer_region_name',
  },
  {
    title: 'Nama Pemilik',
    dataIndex: 'customer_owner',
  },
  {
    title: 'No Telepon',
    dataIndex: 'customer_phone',
  },
  {
    title: 'Email',
    dataIndex: 'customer_email',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Tanggal Registrasi',
    dataIndex: 'customer_registration',
    slots: { customRender: 'formatDate' },
  },
]
